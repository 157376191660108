// Colors

$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #1a202c;

$purple-100: #faf5ff;
$purple-200: #e9d8fd;
$purple-300: #d6bcfa;
$purple-400: #b794f4;
$purple-500: #9f7aea;
$purple-600: #805ad5;
$purple-700: #6b46c1;
$purple-800: #553c9a;
$purple-900: #44337a;

$pink-100: #fff5f7;
$pink-200: #fed7e2;
$pink-300: #fbb6ce;
$pink-400: #f687b3;
$pink-500: #ed64a6;
$pink-600: #d53f8c;
$pink-700: #b83280;
$pink-800: #97266d;
$pink-900: #702459;

$accent-100: $purple-100;
$accent-200: $purple-200;
$accent-300: $purple-300;
$accent-400: $purple-400;
$accent-500: $purple-500;
$accent-600: $purple-600;
$accent-700: $purple-700;
$accent-800: $purple-800;
$accent-900: $purple-900;

// Fonts

$sans: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Source Han Sans SC', 'Source Han Sans CN', 'Source Han Sans', 'Noto Sans CJK SC', 'Noto Sans CJK CN', 'Noto Sans CJK', Arial, 'Microsoft Yahei', 'Microsoft Yahei UI', 'Open Sans', 'Helvetica Neue', sans-serif;
$mono: 'Fira Code', Consolas, Menlo, 'Courier New', Courier, monospace;

// Breakpoints

$sm: 480px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$hd: 1440px;

$breakpoints: ($sm, $md, $lg, $xl, $hd);

// Easing

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
