@import './variables.scss';

*::selection {
  background-color: $accent-500;
  color: $gray-100;
}

body,
html {
  font-family: $sans;
  color: $gray-900;
  background-color: $gray-100;
  font-size: 20px;
}

p {
  line-height: 180%;
}

div,
ul,
li,
p,
h1,
h2,
h3,
footer,
header,
nav {
  box-sizing: border-box;
}

a {
  &,
  &:visited {
    color: $accent-500;
    text-decoration: none;
  }

  &:hover,
  &:active {
    color: $accent-400;
    text-decoration: underline;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), url('./fonts/montserrat-bold-webfont.woff2') format('woff2'), url('./fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Extrabold Italic'), url('./fonts/montserrat-extrabolditalic-webfont.woff2') format('woff2'), url('./fonts/montserrat-extrabolditalic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./fonts/montserrat-regular-webfont.woff2') format('woff2'), url('./fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
